import * as React from "react";
import { AppSettings } from "./api/models";
import { AuthContextProvider } from "./auth/AuthProvider";
import { LoadingView } from "@lantern/react-components";
import useApi from "./api/apiHook";

interface AppSettingsContextValue {
  settings: AppSettings | null;
}
export const AppSettingsContext = React.createContext<AppSettingsContextValue>({ settings: null });
export const useAppSettings = () => React.useContext(AppSettingsContext);

const AppSettingsContextProvider: React.FunctionComponent = (props) => {
  const [appSettings, setAppSettings] = React.useState<AppSettings>();
  const { api } = useApi();

  React.useEffect(() => {
    async function loadAppSettings() {
      const response = await api.getAppSettings();
      setAppSettings(response || undefined);
    }
    loadAppSettings();
  }, [api]);

  return !!appSettings ? (
    <AuthContextProvider settings={appSettings.azureAdSettings}>
      <AppSettingsContext.Provider value={{ settings: appSettings }}>{props.children}</AppSettingsContext.Provider>
    </AuthContextProvider>
  ) : (
    <LoadingView text="Initializing..." />
  );
};

export default AppSettingsContextProvider;
