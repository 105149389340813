import * as React from "react";
import { CssBaseline, ThemeProvider, useMediaQuery } from "@material-ui/core";
import { GetLanternAppTheme } from "@lantern/react-components";
import AppHeader from "./AppHeader";
import NotificationsProvider from "./notifications/NotificationsProvider";
import { useAppSettings } from "./AppSettingsContextProvider";
import AnonymousApolloProvider from "./api/GraphQL/AnonymousApolloProvider";

const AnonymousView: React.FunctionComponent = (props) => {
  const theme = useMediaQuery("(prefers-color-scheme: dark)") ? "dark" : "light";
  const { settings } = useAppSettings();
  return (
    <ThemeProvider theme={GetLanternAppTheme({ authenticated: false, type: theme })}>
      <CssBaseline />
      <NotificationsProvider>
        <AnonymousApolloProvider uri={`${settings?.apiUrl!}graphql`}>
          <AppHeader />
          <main>{props.children}</main>
        </AnonymousApolloProvider>
      </NotificationsProvider>
    </ThemeProvider>
  );
};

export default AnonymousView;
